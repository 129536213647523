/* eslint-disable */

const appId = 'F7FD2183'
const namespace = 'urn:x-cast:com.boombatower.chromecast-dashboard'
let session = null

const sessionListener = newSession => {
  session = newSession
  session.addUpdateListener(isAlive => {
    if (!isAlive) session = null
  })
}

window.__onGCastApiAvailable = isAvailable => {
  if (!isAvailable) return

  const sessionRequest = new chrome.cast.SessionRequest(appId)

  const apiConfig = new chrome.cast.ApiConfig(
    sessionRequest,
    sessionListener,
    () => {},
  )

  chrome.cast.initialize(
    apiConfig,
    () => {},
    () => {},
  )
}

export async function cast () {
  const send = () => session.sendMessage(namespace, {
    type: 'load',
    url: window.location.href,
    refresh: 0,
  })

  if (!session) {
    chrome.cast.requestSession(
      newSession => {
        sessionListener(newSession)
        send()
      },
    )
  } else {
    send()
  }
}
