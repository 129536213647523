import axios from 'axios'
import dagre from 'dagre'
import React, { Component } from "react"
import ReactFlow, {
  isNode
} from 'react-flow-renderer'
import "./App.css"
import { cast } from './chromecast'
import testdata from './testData'


const useTestData = false
// Style settings
const hideHealthy = false
const hideCategories = true
const direction = "LR" // TB
const nodeWidth = direction === "LR" ? 320 : 10
const nodeHeight = direction === "LR" ? 50 : 85
const healthyWeightImportance = 3
const unhealthyWeightImportance = 5
const healthyNodeOpacity = 0.65
const healthyEdgeOpacity = 0.7




const perc2color = (percentage, maxHue = 100, minHue = 0) => {
  if(percentage < 100){ // The scale is linear, so minor differences aren't visible. Probably wanna come up with a better solution for this
    percentage = Math.max(0, percentage - 40)
  }
  const hue = percentage * (maxHue - minHue) + minHue;
  return `hsl(${hue/100}, 85%, 55%)`;
}

const createNode = (node) => {

  var nodeS = {}
  //var shadowCol = "#BDC1C4"
  nodeS["id"] = `${node.type}`
  nodeS["position"] = { x: 0, y: 0}
  nodeS["style"] = {
    color: `Black`,
    border: 'solid 1px grey',
    overflow: 'hidden',
    fontSize: 24,
    fontWeight: `bold`,
    borderRadius: '2px',
    background: perc2color(node.health)
  }

  // Good settings
  if(node.health === 100){
    nodeS["data"] = {label: (<><strong>{node.title}</strong></>)}
    nodeS["style"]["opacity"] = healthyNodeOpacity
    nodeS["style"]["background"] = '#dfdfdf'
  // Bad health settings
  } else{
    nodeS["data"] = {label: (<><strong>{node.title}</strong><br/>{node.health}% </>)}
  }

  return nodeS
}


const createEdge = (target, weight, parent, nodemap) => {
  const targetnode = nodemap[target]

  if(targetnode.hidden || parent.hidden){
    return {}
  } 

  var edge = {
      id: `e${parent.type}-${target}`,
      source: `${parent.type}`,
      target: `${target}`,
      arrowHeadColor: '#dfdfdf',
      arrowHeadType: 'arrow',
      style: {},
      type: "smoothStep",
      weight: `${targetnode.weight}`
    }
    if(parent.health === 100){
      edge["style"]["stroke"] = '#dfdfdf' // If the parent is unaffected by the child, the edge remains green
      edge["style"]["opacity"] = healthyEdgeOpacity
      edge["style"]["strokeWidth"] = healthyWeightImportance //*weight
    } else if(targetnode.health === 100){
      edge["style"]["stroke"] = '#dfdfdf' // If the parent is affected, but the child is fine, we draw the edge color based on the child nodes health since it hasn't affected the parent
      edge["style"]["opacity"] = healthyEdgeOpacity
      edge["style"]["strokeWidth"] = healthyWeightImportance //*weight
    } else{
      edge["style"]["stroke"] = perc2color(targetnode.health) // If both nodes are affected we draw by the childs color
      edge["style"]["strokeWidth"] = weight*unhealthyWeightImportance
    }
    return edge
}





// Data generation
function createNodes(nodes, nodemap){
    if(nodes.length === 0){
      return nodemap
    }
    var n = nodes[0]
    n.hidden = false
    
    // Show node
    if(Object.keys(n).includes("title")) nodemap[n["type"]] = n
    // if(Object.keys(n).includes("dependencies") && n.dependencies.length > 0)createNodes(n.dependencies, n.dependencies, [], nodemap)
    return createNodes(nodes.slice(1), nodemap)
  }

function createEdges(nodes, parent_node = null, created_edges, nodemap){
    if(nodes.length === 0) return created_edges
    var n = nodes[0]
    if(Object.keys(n).includes("weight")) created_edges.push(createEdge(n.type, n.weight, parent_node, nodemap))
    else if(Object.keys(n).includes("dependencies")) for (var cn of n.dependencies)created_edges = created_edges.concat(createEdge(cn.type, cn.weight, n, nodemap))
    return createEdges(nodes.slice(1), parent_node, created_edges, nodemap)
  }



// Layouting
const getLayoutedElements = (elements, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph(
    {
      rankdir: direction,
      ranksep: 10,
      nodesep: 50,
      acyclicer: "greedy"
    });
  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  var els = elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? 'left' : 'top';
      el.sourcePosition = isHorizontal ? 'right' : 'bottom';

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      el.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
    }

    return el;
  });
  return els
};

  const checkNodemap = (nodemap) => {
      // Make check on nodes
      for (var n of Object.values(nodemap)){
        if(n.health === 100 && hideHealthy){ n.hidden = true }
        else if(n.category !== null && n.health === 100 && hideCategories){ n.hidden = true }
      }

    return nodemap
  }

  const anySickChild = (nodes, nodemap) => {
    if(nodes.length === 0) return false
    const n = nodes[0]
    var nn = nodemap[n.type]
    if(nn.health < 100) return true
    var x = anySickChild(nodes.slice(1), nodemap)
    var y = anySickChild(nn.dependencies, nodemap)
    return x || y
  }


class App extends Component {

  componentDidMount = () => {
    this.handleElements()
    var call_interval = 2*60*1000 // 2 minutes

    if(!useTestData){
      setInterval(() => {
        console.log("Fetched new data")
        this.handleElements()
      }, call_interval)
    }

  }
  async handleElements() {
    var nodes = await this.getData()
    this.setData(nodes)
  }

  onLoad = (reactFlowInstance) => {
    this.setState({rf: reactFlowInstance})
    setTimeout(() => reactFlowInstance.fitView(), 0)
  }

  HealthMessages = () => {
    var ret = []
    var nodes = Object.values(this.state.nodemap)
    for(var i in nodes){
      var node = nodes[i]
      if (Object.keys(node).includes("message") && node.message !== ""){
        ret.push(
          <div key={i} className="messageBox">
            <p className="messageTitle"><span>{node.title}</span><span>{node.health}%</span></p>
            <p className="messageText">{node.message}</p>
          </div>
        )
      }
    }
    return ret
  }

  getData = async () => {
      var data = []
      if(useTestData){
        data = testdata
      } else{
        var url = process.env.NODE_ENV === 'development' ? "http://localhost:7071/api/Health" : "https://lasso-healthsystem.azurewebsites.net/api/Health?code=" + new URLSearchParams(window.location.search).get('code');
        data = await axios.post(url, JSON.stringify([])).then(r => r.data).catch(e => [])
      }
      if(data.length === 0) return []
      var nodemape = createNodes(data.healthChecks, {})
      var dnodemap = createNodes(data.dependencies, nodemape)
      const nodemap = checkNodemap(dnodemap)

      // We reverse the order to check which children rely on their parent
      var nodeHasParent = {}
      var nodeHadParent = {}
      for(var n of Object.values(nodemap)){
        for (var cn of n.dependencies){
          if(!nodeHasParent[cn]){
            if(!n.hidden) nodeHasParent[cn.type] = true
          }
          if(!nodeHadParent[cn]){
            nodeHadParent[cn.type] = true
          }
        }
      }


      var nodes = []
      // Convert nodemap to valid nodes
      for(n of Object.values(nodemap)){

        var hadParent = nodeHadParent[n.type]
        var hasParent = nodeHasParent[n.type]
        
        // A qualified node, if it had a parent, has to have a parent still in order to be shown. Any path with a sick child will always be shown
        if((!n.hidden && (hadParent === hasParent)) || anySickChild([n], nodemap)){
          nodes.push(createNode(n))
        } else{
          // Prevent errors when drawing edge
          nodemap[n.type].hidden = true
        }
      }



      this.setState({nodemap: nodemap})

      var nodemapNodes = Object.values(nodemap)
      var edges = createEdges(nodemapNodes, null, [], nodemap)
      var nodesAndEdges = nodes.concat(edges)
      return nodesAndEdges
  }

  setData = (nodes) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    if(nodes){
      const layoutedElements = getLayoutedElements(nodes, direction);
      this.setState({elements: layoutedElements})
    }

    setTimeout(() => {this.state.rf.fitView()}, 1) // Why is this necessary?? weird library
  }


  constructor(props) {
    super(props);
    this.state = {
      elements: [],
      nodemap: [],
      rf: null
    }
    this.getData = this.getData.bind(this);
    this.handleElements = this.handleElements.bind(this);
  }

  render() {
    return (
      <div className="app">
        <div className="messageContainer" style={{ visibility: this.HealthMessages().length > 0 ? 'visible': 'hidden'}}>
          <div className="messageContainerHeader">

          </div>
          <div className="messages">
            <this.HealthMessages/>
          </div>
        </div>
        <div className="graph">
          <ReactFlow
            elements={this.state.elements}
            onLoad={this.onLoad}
            nodesConnectable={false}
            nodesDraggable={false}
            elementsSelectable={false}
            defaultPosition={[0, 0]}
            snapToGrid={true}
            maxZoom={1}
            minZoom={0.001}
          >
          </ReactFlow>
        </div>
        <div className="img-logo" onClick={() => { cast() }}>
          <img src="https://lassox.com/hs-fs/hubfs/lassox_logo-2.png?width=481&name=lassox_logo-2.png" alt="Lasso X logo"/>
        </div>
          {/* <button className="resetViewButton" onClick={() => this.state.rf.fitView()}>Reset view</button> */}
      </div>
    );
  }
}

export default App;